<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Factures</v-card-title>
        <v-card-subtitle>Consultez et téléchargez vos factures</v-card-subtitle>
        <v-card-text>
            <v-list>
                <v-list-item>
                    <v-list-item-title>Recevoir mes factures par mail</v-list-item-title>
                    <v-switch v-model="factures" switch></v-switch>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" :disabled="isChanges()" @click="resetSettings">Annuler</v-btn>
            <v-btn color="success" :disabled="isChanges()" @click="saveSettings">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'Bills',
    data() {
        return {
            factures : JSON.parse(localStorage.getItem('settings')).main.factures,
            factures_backup : JSON.parse(localStorage.getItem('settings')).main.factures,
        }
    },
    methods : {
        saveSettings() {
            console.log(this.factures)
            if (this.factures_backup !== this.factures) {
                const settings = JSON.parse(localStorage.getItem('settings'));
                settings.main.factures = this.factures;
                this.factures_backup = JSON.parse(JSON.stringify(this.factures));
                localStorage.setItem('settings', JSON.stringify(settings));
            }
        },
        resetSettings() {
            this.factures = JSON.parse(JSON.stringify(this.factures_backup));
        },
        isChanges () {
            if (JSON.stringify(this.factures_backup) !== JSON.stringify(this.factures)) {
                return false;
            } else {
                return true;
            }
        }
    },
}
</script>